<template>
  <div class="mobile-voting">
    <div
      class="mobile-voting__item"
      v-for="(media, idx) in $_VotingMixin_voteElementsPre"
      :key="'voting-element-' + idx + media.id"
    >
      <VotingCard
        class="mobile-voting__card"
        :data="media"
        :isVideo="$_VotingMixin_isVideoBehavior"
        :isVideoControls="!$_VotingMixin_isVideoBehaviorEx"
        :mission="$_VotingMixin_mission"
        :ratio="$_VotingMixin_isDrawing ? '16:10' : '4:3'"
        :isGameOver="$_VotingMixin_isGameOver"
        @rated="$_VotingMixin_rated($event.rate, $event.data)"
      />
    </div>
  </div>
</template>

<script>
import VotingMixin from "@/components/GroupTeams/Common/GameModes/Voting/VotingMixin"
import VotingCard from "@/components/GroupTeams/Common/GameModes/Voting/VotingCard.vue"

export default {
  name: "MobileVoting",
  mixins: [VotingMixin],
  components: {
    VotingCard
  },
  data() {
    return {
      localMissionID: null,
      missions: {},
      votes: {}
    }
  },
  async created() {
    this.$_VotingMixin_fetchMissions()
  }
}
</script>

<style lang="scss">
.mobile-voting {
  height: 100%;
  overflow: auto;
  font-size: 1em;
  line-height: 1;

  .orientation-landscape & {
    display: flex;
    align-items: center;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
}

.mobile-voting__item {
  .orientation-portrait & {
    width: 100%;
    margin-bottom: 24px;
  }

  .orientation-landscape & {
    min-width: 320px;
    margin-left: 4px;
    margin-right: 4px;
  }
}
</style>
