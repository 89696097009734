var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile-voting" },
    _vm._l(_vm.$_VotingMixin_voteElementsPre, function (media, idx) {
      return _c(
        "div",
        {
          key: "voting-element-" + idx + media.id,
          staticClass: "mobile-voting__item",
        },
        [
          _c("VotingCard", {
            staticClass: "mobile-voting__card",
            attrs: {
              data: media,
              isVideo: _vm.$_VotingMixin_isVideoBehavior,
              isVideoControls: !_vm.$_VotingMixin_isVideoBehaviorEx,
              mission: _vm.$_VotingMixin_mission,
              ratio: _vm.$_VotingMixin_isDrawing ? "16:10" : "4:3",
              isGameOver: _vm.$_VotingMixin_isGameOver,
            },
            on: {
              rated: function ($event) {
                return _vm.$_VotingMixin_rated($event.rate, $event.data)
              },
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }